



















































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { Estata, ElistText, IbreadcrumbItem } from '@/assets/model/interface';
import { GetArticleList } from '@/http/api/ArticleApi';
import Breadcrum from '@c/breadcrum/Breadcrum.vue';

@Component({
  components: {
    Breadcrum,
  },
})
export default class ArticleList extends Vue {
  private loading: boolean = false;
  private search: any = {
    text: '',
  };
  private breadcrumbList: IbreadcrumbItem[] = [
    { name: '会员服务', path: '/serve' },
    { name: '会员资讯', path: '' },
  ];
  private list: any[] = [];
  private totalPage: number = 0; // 总页数
  private totalCount: number = 0; // 总条数
  private pageNo: number = 1; // 当前页数
  private pageSize: number = 10; // 每页几条
  private tabsIndex: number = 3; // 会员风采 4  会员简介 3
  private articleClass: any = 'consult';
  get listTitle(): string {
    return ElistText[this.articleClass];
  }

  @Watch('$route')
  private wacthRouter(n: any, o: any) {
    this.articleClass = n.params.list;
  }
  @Watch('articleClass')
  private watchArticleClass(n: string, o: string) {
    if (n) {
      this.tabsIndex = 3;
      this.search.text = '';
      this.pageNo = 1;
      // this.getList();
      this.breadcrumbList.splice(1, 1);
      this.breadcrumbList.push({
        name: ElistText[(n as string)],
        path: '',
      });
    }
  }
  private created() {
    this.articleClass = this.$route.params.list;
    this.getList();
  }
  private membermienRecord () {
    this.$router.push({path: '/serve/membermien/record'})
  }
  private membermienAdd () {
    this.$router.push({path: '/serve/membermien/add'})
  }
  // 切换会员简介和会员风采
  private tabsItemClick(num: number): void {
    this.tabsIndex = num;
    this.listSearch();
  }
  // 查看文章详情
  private itemClick(item: any): void {
    if (item.url) {
      window.open(item.url, '_blank');
    } else {
      const { href } = this.$router.resolve({
        path: '/article/detail',
        query: { type: this.articleClass, articleId: item.id },
      });
      window.open(href, '_blank');
    }
  }
  // 获取文章数据列表
  private getList(): void {
    this.loading = true;
    let cmsType: number | string = 3;
    if (this.articleClass === 'memberintroduction') {
      cmsType = this.tabsIndex;
    } else {
      cmsType = Estata[this.articleClass];
    }
    GetArticleList({
      cmsType,
      keyword: this.search.text,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
    }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        const data = res.result;
        this.totalCount = data.total;
        this.totalPage = data.pages;
        this.pageNo = data.current;
        this.list = data.records;
      }
    });
  }
  private handleSizeChange(val: number): void {
    this.pageNo = 1;
    this.pageSize = val;
    this.getList();
  }
  private handleCurrentChange(val: number): void {
    this.pageNo = val;
    this.getList();
  }
  private listSearch(): void {
    this.pageNo = 1;
    this.getList();
  }
}
